import React, { useState } from "react";
import { ArrowNarrowLeftIcon, LockClosedIcon, MailIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from 'react-bootstrap';
import { HashRouter, Link, useHistory } from 'react-router-dom';
import { useUserLoginMutation, useVerifyOtpMutation } from "../../Services/services";

import { Routes } from "routes";
import { FacebookIcon, GithubIcon, TwitterIcon } from "components/BrandIcons";
import OTPInput from "react-otp-input";
import { HashLink } from "react-router-hash-link";
import Countdown from "react-countdown";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [otpVerification, setOtpVerification] = useState(false);
  const [passwordError, setPasswordError] = useState(false); // State for password error
  const history = useHistory();
  const [userLogin] = useUserLoginMutation();
  const [verifiedOtp] = useVerifyOtpMutation();

  const loginData = {
    login: email,
    password: password
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('HIT1');
    userLogin(loginData)
      .then((success) => {
        console.log('HIT2');
        if (success?.data?.statusCode === 200) {
          console.log('HIT3');
          localStorage.setItem("user", JSON.stringify(success.data.data))
          setOtpVerification(true)
          // history.push(Routes.DashboardOverview.path);
        } else if (success?.data?.statusCode > 300) {
          console.error('Login failed passed');
          setPasswordError(true); // Set password error state to true on failure
        }
      })
      .catch((error) => {
        console.error('Login failed', error);
        setPasswordError(true); // Set password error state to true on error
      });
  };

  const otpData = {
    login: email,
    otp: otp
  };

  const verifyOtp = (e) => {
    console.log('Form submitted with:', { email, otp });

    verifiedOtp(otpData)
      .then((success) => {
        console.log('HITTT VERIFY OTP', success);
        if (success?.data?.statusCode === 200) {
          localStorage.setItem("TOKEN", JSON.stringify(success.data.data))
          history.push(Routes.DashboardOverview.path);
        } else if (success?.data?.statusCode > 300) {
          console.error('Login failed passed');
          setPasswordError(true); // Set password error state to true on failure
        }
      })
      .catch((error) => {
        console.error('Login failed', error);
        setPasswordError(true); // Set password error state to true on error
      });
  };

  const maskEmail = (email) => {
    const [username, domain] = email.split('@');
    const maskedUsername = username.charAt(0) + '***' + username.charAt(username.length - 1);
    return `${maskedUsername}@${domain}`;
  }

  const maskedEmail = maskEmail(email);


  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [key, setKey] = useState(Date.now()); // Key to reset the countdown

  const handleResendOtp = () => {
    setIsResendDisabled(true); // Disable resend link
    setKey(Date.now()); // Update key to restart countdown
    // Trigger OTP resend logic here
    handleSubmit();
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Timer has completed
      return (
        <Card.Link
          className="card-link-timer small"
          onClick={handleResendOtp}
          style={{ cursor: "pointer", color: "#F1A034" }}
        >
          Resend OTP?
        </Card.Link>
      );
    } else {
      // Display countdown timer
      return (
        <Card.Link
          className={`card-link-timer ${isResendDisabled ? "text-muted small" : ""}`}
          onClick={isResendDisabled ? null : handleResendOtp}
          style={{ cursor: "default" }}
        >
          {`TIMER: ${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
        </Card.Link>
      );
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center vh-lg-100 mt-5 mt-lg-0 bg-soft">
        <Container>

          {otpVerification === false &&
            (
              <>
                <p className="text-center">
                  <Card.Link as={Link} to="/" className="d-flex align-items-center justify-content-center">
                    <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Back to homepage
                  </Card.Link>
                </p>
                <Row className="justify-content-center form-bg-image" /* style={{ backgroundImage: `url(${BgImage})` }} */>
                  <Col xs={12} className="d-flex align-items-center justify-content-center">
                    <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                      <div className="text-center text-md-center mb-4 mt-md-0">
                        <h3 className="mb-0">Sign in to&nbsp;<span style={{ fontFamily: "fantasy", fontWeight: "bolder" }}>
                          M<span style={{ fontSize: "60px", fontFamily: "fantasy", fontWeight: "bolder", color: "#f1a034" }} >A</span>T
                        </span>
                        </h3>
                      </div>
                      <Form className="mt-4" onSubmit={handleSubmit}>
                        <Form.Group id="email" className="mb-4">
                          <Form.Label>Your Email</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <MailIcon className="icon icon-xs text-gray-600" />
                            </InputGroup.Text>
                            <Form.Control
                              autoFocus
                              required
                              type="email"
                              placeholder="example@company.com"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                        <Form.Group id="password" className="mb-4 position-relative">
                          <Form.Label>Your Password</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <LockClosedIcon className="icon icon-xs text-gray-600" />
                            </InputGroup.Text>
                            <Form.Control
                              required
                              type="password"
                              placeholder="Password"
                              onChange={(e) => {
                                setPassword(e.target.value);
                                setPasswordError(false); // Clear the error when the user types
                              }}
                            />
                            {passwordError && (
                              <InputGroup.Text className="end-0">
                                <ExclamationCircleIcon className="icon icon-xs text-danger" />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                          {passwordError && (
                            <Form.Text className="text-danger mt-2 d-block">
                              Check your password
                            </Form.Text>
                          )}
                        </Form.Group>
                        <div className="d-flex justify-content-between align-items-top mb-4">
                          <Form.Check type="checkbox">
                            <FormCheck.Input id="defaultCheck5" className="me-2" />
                            <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                          </Form.Check>
                          <Card.Link className="small text-end">Lost password?</Card.Link>
                        </div>
                        <div className="d-grid">
                          <Button variant="gray-800" type="submit">
                            Sign in
                          </Button>
                        </div>
                      </Form>

                      <div className="mt-3 mb-4 text-center">
                        <span className="fw-normal">or login with</span>
                      </div>
                      <div className="d-flex justify-content-center my-4">
                        <Button variant="outline-gray-500" className="btn-icon-only btn-pill me-2">
                          <FacebookIcon size="xs" color="currentColor" />
                        </Button>
                        <Button variant="outline-gray-500" className="btn-icon-only btn-pill me-2">
                          <TwitterIcon size="xs" color="currentColor" />
                        </Button>
                        <Button variant="outline-gray-500" className="btn-icon-only btn-pill">
                          <GithubIcon size="xs" color="currentColor" />
                        </Button>
                      </div>
                      <div className="d-flex justify-content-center align-items-center mt-4">
                        <span className="fw-normal">
                          Not registered?
                          <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                            {` Create account `}
                          </Card.Link>
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}


          {otpVerification === true && (

            <>
              <div className="text-center">
                <Card.Link
                  as="div"
                  onClick={() => setOtpVerification(false)}
                  className="d-flex align-items-center justify-content-center"
                  style={{ cursor: 'pointer' }}
                >
                  <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Back to SignIn
                </Card.Link>
              </div>


              <Row>
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                    <div className="text-center text-md-center mt-md-0">
                      <h3 className="mb-0" style={{
                        position: "relative",
                        bottom: "25px"
                      }}><span style={{ fontFamily: "fantasy", fontWeight: "bolder" }}>
                          M<span style={{ fontSize: "60px", fontFamily: "fantasy", fontWeight: "bolder", color: "#f1a034" }} >A</span>T
                        </span><br />
                        Verify OTP
                      </h3>
                    </div>
                    <Form className="text-center">
                      <Form.Group id="email" className="mb-4">
                        <div className=" mb-4">
                          <h6>Please enter the 6 digit OTP sent to your email {maskedEmail}.</h6>
                        </div>
                        <InputGroup className="justify-content-center">
                          <div className="formInput">
                            <OTPInput
                              onChange={setOtp}
                              value={otp}
                              inputStyle="inputStyle"
                              numInputs={6}
                              renderSeparator={<span></span>}
                              renderInput={(props) => <input {...props} />}
                            />
                          </div>
                        </InputGroup>
                        <div className="d-grid mt-3">
                          <Button variant="gray-800" type="submit" onClick={verifyOtp}>
                            Verify
                          </Button>
                        </div>
                      </Form.Group>

                      <div className="d-flex flex-column align-items-start mb-4">
                        <Form.Label className="mb-0 small">Did not receive OTP code?</Form.Label>
                        <Form.Check type="checkbox" disabled={isResendDisabled}>
                          <Form.Check.Label htmlFor="defaultCheck5" className="mb-0">
                            <Countdown
                              key={key}
                              date={Date.now() + 10000}
                              renderer={renderer}
                            />
                          </Form.Check.Label>
                        </Form.Check>
                      </div>
                    </Form>

                  </div>
                </Col>
              </Row>
            </>
          )}

        </Container>
      </section>
    </main>
  );
};

export default Signin;
