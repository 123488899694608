export const getWhatsappTemplatesMutation = (builder) => ({
  getWhatsappTemplates: builder.mutation({
    query: ({ page = 1, template_id = '' }) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
      return {
        url: '/whatsapp_template',
        method: 'GET',
        params: { page, template_id },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      }
    },
  }),
});