export const createWhatsappTemplateMutation = (builder) => ({
  createWhatsappTemplate: builder.mutation({
    query: (credentials) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
      return {
        url: '/whatsapp_template',
        method: 'POST',
        body: credentials,
        headers: {
          'Authorization': token
        },
      }
    },
  }),
});