export const editWhatsappTemplateMutation = (builder) => ({
  editWhatsappTemplate: builder.mutation({
    query: ({ id, templateData }) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
      return {
        url: `/whatsapp_template`,
        method: 'PUT',
        params: { id },
        body: templateData,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      }
    },
  }),
});