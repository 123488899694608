//by shreya saraswat
import React, { useState } from "react";
import { Col, Row, Nav, Tab, Form, ListGroup, Button, ToastContainer, Toast } from 'react-bootstrap';
import smsPic from "../../assets/img/mobileScreens/SMS.png"
import { WhatsappTemplateComponent } from "./WhatsappComponent";
import { RCSTemplateComponent } from "./RCSComponent";
import { useCreateSMSTemplateMutation } from "Services/services";



export default () => {
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success');

    const [formValues, setFormValues] = useState({});
    const [createTemp] = useCreateSMSTemplateMutation();
    const handleChange = (e) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.value,
            language: "En"
        }));
    };


    const submitSMS = (e) => {
        e.preventDefault();
        createTemp(formValues)
            .then((success) => {
                if (success?.data?.statusCode >= 200 && success?.data?.statusCode <= 204) {
                    setToastMessage(success?.data?.message);
                    setToastVariant('success');
                } else if (success?.data?.statusCode > 300) {
                    setToastMessage(success?.data?.message || 'Upload failed!');
                    setToastVariant('danger');
                }
                setShowToast(true);
            })
            .catch((error) => {
                setToastMessage(error.message || 'Upload failed');
                setToastVariant('danger');
                setShowToast(true);
            });
    }

    return (
        <>
            <ToastContainer position="top-end" className="p-3">
                <Toast
                    bg={toastVariant}
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    delay={3000}
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">Notification</strong>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>

            <Tab.Container defaultActiveKey="Whatsapp">
                <Row className="py-4">
                    <Col lg={2}>
                        <Nav fill variant="pills" className="flex-lg-column vertical-tab">
                            <Nav.Item>
                                <Nav.Link eventKey="SMS" className="mb-md-3">
                                    SMS
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Whatsapp" className="mb-md-3">
                                    WhatApp
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="RCS" className="mb-md-3">
                                    RCS
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col lg={10}>
                        <Tab.Content>
                            <Tab.Pane eventKey="SMS">
                                <Row>
                                    <Col xs={8} lg={8}>
                                        <Form className="card p-3">
                                            <Row className="mb-3">
                                                <Form.Group as={Col} lg={6}>
                                                    <Form.Label>Template Name</Form.Label>
                                                    <Form.Control type="text" required placeholder="abc" maxLength={100} name="template_name" onChange={handleChange} />
                                                </Form.Group>
                                                <Form.Group as={Col} lg={6}>
                                                    <Form.Label>Category</Form.Label>
                                                    <Form.Select name="category" onChange={handleChange}>
                                                        <option defaultValue>PROMOTIONAL</option>
                                                        <option>TRANSACTIONAL</option>
                                                        <option>OTP</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} lg={6}>
                                                    <Form.Label>Language</Form.Label>
                                                    <Form.Select disabled>
                                                        <option defaultValue>English</option>
                                                        <option>english</option>
                                                        <option>hindi</option>
                                                        <option>punjabi</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group as={Col} lg={6}>
                                                    <Form.Label>Sender ID</Form.Label>
                                                    <Form.Control type="text" required placeholder="abc" maxLength={100} name="header" onChange={handleChange} />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} lg={6}>
                                                    <Form.Label>PE ID</Form.Label>
                                                    <Form.Control type="text" required placeholder="abc" maxLength={100} name="pe_id" onChange={handleChange} />
                                                </Form.Group>
                                                <Form.Group as={Col} lg={6}>
                                                    <Form.Label>Template ID</Form.Label>
                                                    <Form.Control type="text" required placeholder="abc" maxLength={100} name="template_id" onChange={handleChange} />
                                                </Form.Group>
                                            </Row>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Content Body</Form.Label>
                                                <Form.Control as="textarea" rows="15" required placeholder="Enter Message Content" maxLength={160} name="template_content" onChange={handleChange} />
                                            </Form.Group>
                                            <ListGroup horizontal className="border-0">
                                                <ListGroup.Item variant="info">GSM 7</ListGroup.Item>
                                                <ListGroup.Item variant="info">160 Char.</ListGroup.Item>
                                                <ListGroup.Item variant="info">1 Credit</ListGroup.Item>
                                            </ListGroup>
                                        </Form>
                                        <div className="d-flex mt-3">
                                            <Button variant="gray-800" type="submit" className="animate-up-2">
                                                Verify
                                            </Button>
                                            <Button variant="gray-800" type="submit" className="ms-2 animate-up-2" onClick={submitSMS}>
                                                Submit
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col xs={4} lg={4}>
                                        <div className="img-cont">
                                            <img src={smsPic} alt="sms preview" />
                                        </div>
                                    </Col>
                                </Row>
                            </Tab.Pane>

                            <Tab.Pane eventKey="Whatsapp">
                                <WhatsappTemplateComponent />
                            </Tab.Pane>

                            <Tab.Pane eventKey="RCS">
                                <RCSTemplateComponent />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    );
};
