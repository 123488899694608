//by shreya saraswat
import React, { useState } from "react";
import { Col, Row, Tab, Nav, Breadcrumb, Card, Form, Button } from 'react-bootstrap';
import { HomeIcon } from "@heroicons/react/solid";
import { Link } from 'react-router-dom';
import { IoMdBackspace } from "react-icons/io";
import { Routes } from "routes";
import { PlusIcon } from "@heroicons/react/outline";
import { DropFilesForm } from "components/Forms";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { AiFillMessage } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { XCircleIcon } from "@heroicons/react/outline";
import { BulkComponent } from "./createBulkCamp";

export default () => {
    const [dataType, setDataType] = useState('');

    const handleDataType = (e) => {
        console.log('selection data type', e.target.id);
        setDataType(e.target.id);
    }

    const [selected, setSelected] = useState('');
    const [condition, setCondition] = useState(false);
    const handleSelection = (event) => {
        setSelected(event.currentTarget.value);
    };
    const handleCondition = (e) => {
        console.log('jjjjjjjj', e.target.value);
    }
    const deleteCondition = () => {
        setCondition(false);
    }
    const handleShow = () => {
        setCondition(true);
    }


    const handleSubmit = () => {
        console.log('saved');
    }
    return (
        <>
            <div className="d-flex">
                <div className="d-block mt-3">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                        <Breadcrumb.Item>Campaign List</Breadcrumb.Item>
                        <Breadcrumb.Item active>Create Campaign</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Create Campaign</h4>
                    {/* <p className="mb-0">Your SMS/WhatsApp/RCS template analytics dashboard.</p> */}
                </div>
                <Card.Link as={Link} to={Routes.Campaign.path} className="fw-bold ms-auto text-dark hover:underline d-inline-flex align-items-center mb-2">
                    <IoMdBackspace className="icon icon-xs me-2" /> Back to Campaigns
                </Card.Link>
            </div>

            <Row className="mt-3">
                <Col xs={12} xl={12}>
                    <Tab.Container defaultActiveKey="api-push">
                        <Nav fill variant="pills" className="flex-column flex-sm-row">
                            <Nav.Item>
                                <Nav.Link eventKey="api-push" className="mb-sm-3 mb-md-0">
                                    API Push
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="bulk-campaign" className="mb-sm-3 mb-md-0">
                                    Bulk Campaign
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content className="mt-4">
                            <Tab.Pane eventKey="api-push">
                                <Card border="0" className="table-wrapper table-responsive shadow">
                                    <Card.Body>
                                        <Col lg={12}>
                                            <Form>
                                                <Form.Group as={Col} xs={12} md={6} className="mb-4">
                                                    <Form.Label>Campaign Name</Form.Label>
                                                    <Form.Control type="text" required placeholder="abc" maxLength={50} />
                                                </Form.Group>

                                                <Form.Group as={Col} className="mb-4">
                                                    <Form.Label>Select Message Type</Form.Label>
                                                    <div className="d-flex flex-wrap">
                                                        <Button variant={selected === 'sms' ? "gray-800" : "white"} className="d-grid me-3" onClick={handleSelection} value={'sms'}>
                                                            <AiFillMessage className="mb-2 icon-clg text-gray-400" /> SMS
                                                        </Button>
                                                        <Button variant={selected === 'WhatsApp' ? "gray-800" : "white"} className="d-grid me-3" onClick={handleSelection} value={'WhatsApp'}>
                                                            <IoLogoWhatsapp className="mb-2 icon-clg text-gray-400" />WhatsApp
                                                        </Button>
                                                        <Button variant={selected === 'rcs' ? "gray-800" : "white"} className="d-grid" onClick={handleSelection} value={'rcs'}>
                                                            <BiSolidMessageSquareDetail className="mb-2 icon-clg text-gray-400" />RCS
                                                        </Button>
                                                    </div>
                                                </Form.Group>

                                                <Form.Group as={Col} xs={12} md={6} className="mb-4">
                                                    <Form.Label>Template</Form.Label>
                                                    <Form.Select>
                                                        <option defaultValue>Select Template</option>
                                                        <option>One</option>
                                                        <option>Two</option>
                                                        <option>Three</option>
                                                    </Form.Select>
                                                </Form.Group>

                                                <Form.Group as={Col} xs={12} md={6} className="mb-2">
                                                    <Form.Label>Condition</Form.Label>
                                                    <div className="d-flex align-items-center mb-2">
                                                        <Form.Select onChange={handleCondition}>
                                                            <option defaultValue >Success</option>
                                                            <option>Failure</option>
                                                        </Form.Select>
                                                        <Form.Select className="ms-2" onChange={handleCondition}>
                                                            <option defaultValue>SMS</option>
                                                            <option>WhatsApp</option>
                                                            <option>RCS</option>
                                                        </Form.Select>
                                                        <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                                                            <Card.Link className="ms-2" onClick={() => deleteCondition()}>
                                                                <XCircleIcon className="icon icon-xs text-danger" />
                                                            </Card.Link>
                                                        </OverlayTrigger>
                                                    </div>
                                                </Form.Group>
                                                {condition ? <Form.Group as={Col} xs={12} md={6} className="mb-4">
                                                    <div className="d-flex align-items-center mb-2">
                                                        <Form.Select onChange={handleCondition}>
                                                            <option defaultValue >Success</option>
                                                            <option>Failure</option>
                                                        </Form.Select>
                                                        <Form.Select className="ms-2" onChange={handleCondition}>
                                                            <option defaultValue>SMS</option>
                                                            <option>WhatsApp</option>
                                                            <option>RCS</option>
                                                        </Form.Select>
                                                        <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                                                            <Card.Link className="ms-2" onClick={() => deleteCondition()}>
                                                                <XCircleIcon className="icon icon-xs text-danger" />
                                                            </Card.Link>
                                                        </OverlayTrigger>
                                                    </div>
                                                </Form.Group> :
                                                    <Button variant="outline-gray-500" className="d-grid mb-4 rounded-pill" onClick={handleShow}>
                                                        Add Condition
                                                    </Button>
                                                }

                                                <Form.Group as={Col} xs={12} lg={6} className="mb-4">
                                                    <Form.Label>Select Domain</Form.Label>
                                                    <Form.Select>
                                                        <option defaultValue>Open this select menu</option>
                                                        <option>One</option>
                                                        <option>Two</option>
                                                        <option>Three</option>
                                                    </Form.Select>
                                                </Form.Group>

                                                <Button as={Link} to={Routes.Campaign.path} variant="gray-800" type="submit" className="mt-2 animate-up-2 mb-2" onClick={handleSubmit}>
                                                    Submit
                                                </Button>
                                            </Form>
                                        </Col>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>

                            <Tab.Pane eventKey="bulk-campaign">
                               <BulkComponent />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>
        </>
    )
}