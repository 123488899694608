//by shreya saraswat
import React, { useState } from "react";
import { Col, Row, Tab, Nav, Breadcrumb, Card, Form, Button } from 'react-bootstrap';
import { HomeIcon } from "@heroicons/react/solid";
import { Link } from 'react-router-dom';
import { IoMdBackspace } from "react-icons/io";
import { Routes } from "routes";
import { PlusIcon } from "@heroicons/react/outline";
import { DropFilesForm } from "components/Forms";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { AiFillMessage } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { XCircleIcon } from "@heroicons/react/outline";

export const BulkComponent = () => {
    const [dataType, setDataType] = useState('');

    const handleDataType = (e) => {
        console.log('selection data type', e.target.id);
        setDataType(e.target.id);
    }

    const [selected, setSelected] = useState('');
    const [condition, setCondition] = useState(false);
    const handleSelection = (event) => {
        setSelected(event.currentTarget.value);
    };
    const handleCondition = (e) => {
        console.log('jjjjjjjj', e.target.value);
    }
    const deleteCondition = () => {
        setCondition(false);
    }
    const handleShow = () => {
        setCondition(true);
    }


    const handleSubmit = () => {
        alert('saved');
    }

    return (
        <Card border="0" className="table-wrapper table-responsive shadow">
            <Card.Body>
                <Col lg={12}>
                    <Form>
                        <Form.Group as={Col} xs={12} md={6} className="mb-4">
                            <Form.Label>Campaign Name</Form.Label>
                            <Form.Control type="text" required placeholder="abc" maxLength={50} />
                        </Form.Group>

                        <Row className="mb-4">
                            <Form.Group as={Col} xs={12} lg={6}>
                                <Form.Label>Select Data Type</Form.Label>
                                <Form.Check
                                    name="dataType"
                                    type="radio"
                                    id={`contactList`}
                                    label={`Address Book`}
                                    onChange={handleDataType}
                                />
                                <Form.Check
                                    name="dataType"
                                    type="radio"
                                    id={`apiList`}
                                    label={`API List`}
                                    onChange={handleDataType}
                                />
                                <Form.Check
                                    name="dataType"
                                    type="radio"
                                    id={`fileUpload`}
                                    label={`File Upload`}
                                    onChange={handleDataType}
                                />
                            </Form.Group>
                            {dataType === 'contactList' &&
                                <Form.Group as={Col} xs={12} lg={6}>
                                    <Form.Label>Select From Contact Lists</Form.Label>
                                    <Form.Select>
                                        <option defaultValue>Open this select menu</option>
                                        <option>One</option>
                                        <option>Two</option>
                                        <option>Three</option>
                                    </Form.Select>
                                </Form.Group>
                            }
                            {dataType === 'apiList' &&
                                <Form.Group as={Col} xs={12} lg={6}>
                                    <Form.Label>Select From API Lists</Form.Label>
                                    <Form.Select>
                                        <option defaultValue>Open this select menu</option>
                                        <option>One</option>
                                        <option>Two</option>
                                        <option>Three</option>
                                    </Form.Select>
                                </Form.Group>
                            }
                            {dataType === 'fileUpload' &&
                                <Form.Group as={Col} xs={12} lg={6} className="px-4">
                                    <DropFilesForm />
                                </Form.Group>
                            }
                        </Row>

                        <Form.Group as={Col} className="mb-4">
                            <Form.Label>Select Message Type</Form.Label>
                            <div className="d-flex flex-wrap">
                                <Button variant={selected === 'sms' ? "gray-800" : "white"} className="d-grid me-3" onClick={handleSelection} value={'sms'}>
                                    <AiFillMessage className="mb-2 icon-clg text-gray-400" /> SMS
                                </Button>
                                <Button variant={selected === 'WhatsApp' ? "gray-800" : "white"} className="d-grid me-3" onClick={handleSelection} value={'WhatsApp'}>
                                    <IoLogoWhatsapp className="mb-2 icon-clg text-gray-400" />WhatsApp
                                </Button>
                                <Button variant={selected === 'rcs' ? "gray-800" : "white"} className="d-grid" onClick={handleSelection} value={'rcs'}>
                                    <BiSolidMessageSquareDetail className="mb-2 icon-clg text-gray-400" />RCS
                                </Button>
                            </div>
                        </Form.Group>



                        <Form.Group as={Col} xs={12} md={6} className="mb-4">
                            <Form.Label>Template</Form.Label>
                            <Form.Select>
                                <option defaultValue>Select Template</option>
                                <option>One</option>
                                <option>Two</option>
                                <option>Three</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} md={6} className="mb-2">
                            <Form.Label>Condition</Form.Label>
                            <div className="d-flex align-items-center mb-2">
                                <Form.Select onChange={handleCondition}>
                                    <option defaultValue >Success</option>
                                    <option>Failure</option>
                                </Form.Select>
                                <Form.Select className="ms-2" onChange={handleCondition}>
                                    <option defaultValue>SMS</option>
                                    <option>WhatsApp</option>
                                    <option>RCS</option>
                                </Form.Select>
                                <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                                    <Card.Link className="ms-2" onClick={() => deleteCondition()}>
                                        <XCircleIcon className="icon icon-xs text-danger" />
                                    </Card.Link>
                                </OverlayTrigger>
                            </div>
                        </Form.Group>
                        {condition ? <Form.Group as={Col} xs={12} md={6} className="mb-4">
                            <div className="d-flex align-items-center mb-2">
                                <Form.Select onChange={handleCondition}>
                                    <option defaultValue >Success</option>
                                    <option>Failure</option>
                                </Form.Select>
                                <Form.Select className="ms-2" onChange={handleCondition}>
                                    <option defaultValue>SMS</option>
                                    <option>WhatsApp</option>
                                    <option>RCS</option>
                                </Form.Select>
                                <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                                    <Card.Link className="ms-2" onClick={() => deleteCondition()}>
                                        <XCircleIcon className="icon icon-xs text-danger" />
                                    </Card.Link>
                                </OverlayTrigger>
                            </div>
                        </Form.Group> :
                            <Button variant="outline-gray-500" className="d-grid mb-4 rounded-pill" onClick={handleShow}>
                                Add Condition
                            </Button>
                        }

                        <Form.Group as={Col} xs={12} lg={6} className="mb-4">
                            <Form.Label>Select Domain</Form.Label>
                            <Form.Select>
                                <option defaultValue>Open this select menu</option>
                                <option>One</option>
                                <option>Two</option>
                                <option>Three</option>
                            </Form.Select>
                        </Form.Group>
                        {/* //On one time - ek baar hi date leni hai, recurring me end date time bhi ayega */}
                        {/* campaign list api */}

                        <Button as={Link} to={Routes.Campaign.path} variant="gray-800" type="submit" className="mt-2 animate-up-2 mb-2" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Card.Body>
        </Card>
    )
}