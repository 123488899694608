import React, { useEffect, useState } from "react";
import { CalendarIcon, CheckIcon, CogIcon, CreditCardIcon, CurrencyRupeeIcon, HomeIcon, PlusIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Card } from 'react-bootstrap';
import { useUserDropDownMutation, useGetUserBalanceMutation, useInsertTransactionMutation } from "Services/services";
import { WalletTable } from "components/Tables";
import TRANSACTIONS_DATA from "data/wallet";
import Select from 'react-select';
import users from "data/users";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default () => {
  const [transactions, setTransactions] = useState(TRANSACTIONS_DATA.map(t => ({ ...t, show: true })));
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("all");
  const [coordinatedCrm, setCoordinatedCrm] = useState(null);
  const [userDropDown] = useUserDropDownMutation();
  const [balance] = useGetUserBalanceMutation();
  const [transaction] = useInsertTransactionMutation();
  const [amount, setAmount] = useState(0);
  const [users, setUsers] = useState();
  const [user, setUser] = useState();
  const [userId, setUserId] = useState();
  const transactionTypeOptions = [
    { value: 'credit', label: 'Credit' },
    { value: 'debit', label: 'Debit' }
  ]
  const [transactionalType, setTransactionalType] = useState(transactionTypeOptions[0]);


  useEffect(() => {
    fetchUserDropdown();
  }, [amount])

  const fetchUserDropdown = async () => {
    userDropDown({ user_type: "" })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const userOptions = response.data.data.user_details.map(user => ({
            value: user.id, // Assuming `id` is the identifier you want to use
            label: user.user_name // Assuming `username` is the label you want to display
          }));
          setUsers(userOptions);
        } else {
          console.error('Failed to fetch user data');
        }
      })
      .catch((error) => {
        console.error('Error fetching user data', error);
      });
  };




  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    const newTransactions = transactions.map(t => {
      const subscription = t.subscription.toLowerCase();
      const shouldShow = subscription.includes(newSearchValue)
        || `${t.price}`.includes(newSearchValue)
        || t.status.includes(newSearchValue)
        || `${t.invoiceNumber}`.includes(newSearchValue);

      return ({ ...t, show: shouldShow });
    });

    setSearchValue(newSearchValue);
    setTransactions(newTransactions);
  };

  const changeStatusValue = (e) => {
    const newStatusValue = e.target.value;
    const newTransactions = transactions.map(u => ({ ...u, show: u.status === newStatusValue || newStatusValue === "all" }));

    setStatusValue(newStatusValue);
    setTransactions(newTransactions);
  };
  const RequiredLabel = ({ label }) => (
    <Form.Label>
      {label}
      <span className="text-danger"> * </span>
    </Form.Label>
  );

  const [addAmount, setAddAmount] = useState('');


  const selectUserType = (selectedOption) => {
    console.log('iddddd', selectedOption.value);

    balance({ user_id: selectedOption.value })
      .then((success) => {
        if (success?.data?.statusCode === 200) {
          // setisVerifiedPhone(true);
          setAmount(success.data.data.totalBalance)
        } else if (success?.data?.statusCode > 300) {
          // setisVerifiedPhone(false);
          setAmount(0)

          console.error("Verification failed");
        }
      })
      .catch((error) => {
        // setisVerifiedPhone(false);
        console.error("Verification failed", error);
      });


    setUserId(selectedOption.value)
  };


  const formatAmountWithIndianCommas = (amount) => {
    const x = amount.split('.');
    let lastThree = x[0].slice(-3);
    const otherNumbers = x[0].slice(0, -3);
    if (otherNumbers !== '') {
      lastThree = ',' + lastThree;
    }
    const result = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
    return x.length > 1 ? result + '.' + x[1] : result;
  };

  const handleAmountChange = (e) => {
    const inputValue = e.target.value.replace(/,/g, '').replace(/₹/g, ''); // Remove existing commas and ₹ symbol
    if (!isNaN(inputValue) && inputValue.length <= 10) {
      const formattedValue = `₹${formatAmountWithIndianCommas(inputValue)}`;
      setAddAmount(formattedValue);
    }
  };

  const postData = {
    parent_id: 1,
    user_id: user?.value,
    amount: parseFloat(addAmount.replace(/[^0-9.-]+/g, "")) || 0, // Convert string to number
    credit: transactionalType?.value === 'credit', // Assuming the option value indicates credit or debit
    debit: transactionalType?.value === 'debit',
    status: "completed",
    remarks: `SMS Amount ${transactionalType?.value}`
  }
  const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary me-3',
      cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
  }));
  const handleSubmit = (e) => {
    e.preventDefault();

    transaction(postData)
      .then((success) => {
        if (success?.data?.statusCode === 200 || success?.data?.statusCode === 201) {
          // setisVerifiedPhone(true);

          setUser("")
          setAddAmount("")
          setAmount(0)
          const confirmMessage = `The amount has been ${transactionalType?.value} successfully.`;
          SwalWithBootstrapButtons.fire('Approved', confirmMessage, 'success');
          console.log('TRANSACT SUCCESS');
          fetchUserDropdown();
        } else if (success?.data?.statusCode > 300) {
          // setisVerifiedPhone(false);
          console.error("Verification failed");
        }
      })
      .catch((error) => {
        // setisVerifiedPhone(false);
        console.error("Verification failed", error);
      });
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item>MAT</Breadcrumb.Item>
            <Breadcrumb.Item active>Wallet</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Wallet</h4>
        </div>
      </div>

      <Card as="form" border="0" className="shadow p-3 pb-4 mb-4" onSubmit={handleSubmit}>
        <Card.Header className="mx-lg-4 p-0 py-3 py-lg-4 mb-4 mb-md-0">
          <h5 className="mb-0">Wallet Management</h5>
        </Card.Header>
        <Card.Body className="p-0 p-md-4">
          <Row>
            <Col md={6} className="mb-3 text-left">
              <Form.Group id="cardNameLabel">
                <RequiredLabel label="User" />
                <Select
                  options={users}
                  placeholder="--Select User--"
                  className="mb-0"
                  isSearchable={true}
                  value={user}
                  onChange={(selectedOption) => {
                    setUser(selectedOption);
                    selectUserType(selectedOption);
                  }}
                />
              </Form.Group>
            </Col>

            <Col xs={6} className="mb-4 align-item-right">
              <Form.Group id="cardNumberLabel">
                <Form.Label>Current Wallet Amount</Form.Label>
                <InputGroup>
                  <InputGroup.Text className="text-gray-600">
                    <CurrencyRupeeIcon className="icon icon-xs" />
                  </InputGroup.Text>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="0"
                    value={`${amount || 0}/-`}
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3 text-left">
              <Form.Group id="transactionalTypeLabel">
                <RequiredLabel label="Transactional Type" />
                <Select
                  options={transactionTypeOptions}
                  placeholder="--Select Transactional Type--"
                  className="mb-0"
                  isSearchable={true}
                  value={transactionalType}
                  onChange={setTransactionalType}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6} className="mb-4 mb-lg-0">
              <Form.Group id="cardCVCLabel">
                <RequiredLabel label="Add Amount" />
                <InputGroup>
                  <InputGroup.Text className="text-gray-600">
                    <CreditCardIcon className="icon icon-xs" />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Ex: ₹1,000"
                    value={addAmount}
                    onChange={handleAmountChange}
                    maxLength={14} // Adjusted for ₹ symbol and commas
                  />
                </InputGroup>
              </Form.Group>
            </Col>



            <Col xs={12} className="mt-4">
              <Button variant="gray-800" className="animate-up-2 mt-2" type="submit">
                Submit
              </Button>
            </Col>

          </Row>
        </Card.Body>
      </Card>

      {/* <div className="table-settings mb-4">
        <Row className="d-flex justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-400">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search orders"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select value={statusValue} className="fmxw-200 d-none d-md-inline" onChange={changeStatusValue}>
              <option value="all">All</option>
              <option value="debit">Debit</option>
              <option value="credit">Credit</option>
            </Form.Select>
          </Col>
          <Col xs={3} lg={4} className="d-flex justify-content-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                <CogIcon className="icon icon-sm" />
                <span className="visually-hidden">Toggle Dropdown</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                <small className="ps-3 fw-bold text-dark">Show</small>
                <Dropdown.Item className="d-flex align-items-center fw-bold">
                  10 <CheckIcon className="icon icon-xxs ms-auto" />
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold rounded-bottom">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div> */}

      <WalletTable />
    </>
  );
};
