//by shreya saraswat
import React from "react";
import { Col, Row, Tab, Nav, Breadcrumb } from 'react-bootstrap';
import { SMSTemplate, TemplateTable } from "components/Tables";
import { HomeIcon } from "@heroicons/react/outline";


export default () => {

    return (
        <>
            <div className="d-block mt-3">
                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                    <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                    <Breadcrumb.Item>MAT</Breadcrumb.Item>
                    <Breadcrumb.Item active>Template Lists</Breadcrumb.Item>
                </Breadcrumb>
                <h4>Template Lists</h4>
                <p className="mb-0">Your SMS/WhatsApp/RCS template analytics dashboard.</p>
            </div>

            <Row className="mt-3">
                <Col xs={12} xl={12}>
                    <Tab.Container defaultActiveKey="SMS">
                        <Nav fill variant="pills" className="flex-column flex-sm-row">
                            <Nav.Item>
                                <Nav.Link eventKey="SMS" className="mb-sm-3 mb-md-0">
                                    SMS
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="WhatsApp" className="mb-sm-3 mb-md-0">
                                    WhatsApp
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="RCS" className="mb-sm-3 mb-md-0">
                                    RCS
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content className="mt-4">
                            <Tab.Pane eventKey="SMS">
                                <SMSTemplate />
                            </Tab.Pane>

                            <Tab.Pane eventKey="WhatsApp">
                                <TemplateTable />
                            </Tab.Pane>

                            <Tab.Pane eventKey="RCS">
                                coming soon !
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>
        </>
    );
};
