//by shreya saraswat
import CopiedAlert from "pages/components/CopyAlert";
import React, { useState } from "react";
import { Col, Row, Button, Form } from 'react-bootstrap';



export const APIComponent = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [text, setText] = useState(false);

    const [code, setCode] = useState({addAPI: 'cURL', churnAPI: 'cURL'});
    const handleCode = (e) => {
        setCode({
            ...code,
            [e.target.id]: e.target.value
        });
    };

    const addCurl = `curl --location 'https://mat.blockcube.co/api/upload_list?list_name=my_api_list&remark=testing1&msisdn=9818049959' \
    --header 'Authorization: {{token}}'`;
    const addHttp = `GET /api/upload_list?list_name=my_api_list&remark=testing1&msisdn=9818049959 HTTP/1.1
    Host: mat.blockcube.co
    Authorization: {{token}}`;

    const churnCurl = `curl --location 'https://mat.blockcube.co/api/churn_list?list_name=my_api_list&msisdn=9818049959' \
    --header 'Authorization: {{token}}'`;
    const churnHttp = `GET /api/churn_list?list_name=my_api_list&msisdn=9818049959 HTTP/1.1
    Host: mat.blockcube.co
    Authorization: {{token}}`;

    const copyToClipboard = (e) => {
        if (e.target.id === 'code1') {
            if (code.addAPI === 'cURL') {
                navigator.clipboard.writeText(addCurl).then(() => {
                    setShowAlert(true);
                });
            } else if (code.addAPI === 'HTTP') {
                navigator.clipboard.writeText(addHttp).then(() => {
                    setShowAlert(true);
                });
            }
            setText('Copied ADD API');
        } else if (e.target.id === 'code2') {
            if (code.churnAPI === 'cURL') {
                navigator.clipboard.writeText(churnCurl).then(() => {
                    setShowAlert(true);
                });
            } else if (code.churnAPI === 'HTTP') {
                navigator.clipboard.writeText(churnHttp).then(() => {
                    setShowAlert(true);
                });
            }
            setText('Copied CHURN API');
        }
    };

    return (
        <Row>
            <CopiedAlert showAlert={showAlert} setShowAlert={setShowAlert} value={text} />
            <Col sm={6}>
                <div className="d-flex justify-content-between mb-2">
                    <p className="h6 card-text">ADD API</p>
                    <Form.Select onChange={handleCode} value={code.addAPI || ''} id="addAPI" className="fmxw-100 p-2">
                        <option defaultValue>cURL</option>
                        <option>HTTP</option>
                    </Form.Select>
                </div>
                <pre style={{ height: '150px', whiteSpace: 'pre-wrap', wordBreak: 'break-word', background: '#f8f9fa', padding: '10px', borderRadius: '5px', border: '1px solid #dee2e6' }}>
                    {code.addAPI === 'HTTP' ? addHttp : addCurl}
                </pre>
                <Button onClick={copyToClipboard} id="code1" title="Copy to Clipboard" variant="primary" style={{ marginTop: '10px' }}>
                    Copy
                </Button>
            </Col>

            <Col sm={6} className="mt-4 mt-sm-0">
                <div className="d-flex justify-content-between mb-2">
                    <p className="h6 card-text">CHURN API</p>
                    <Form.Select onChange={handleCode} value={code.churnAPI || ''} id="churnAPI" className="fmxw-100 p-2">
                        <option defaultValue>cURL</option>
                        <option>HTTP</option>
                    </Form.Select>
                </div>
                <pre style={{ height: '150px', whiteSpace: 'pre-wrap', wordBreak: 'break-word', background: '#f8f9fa', padding: '10px', borderRadius: '5px', border: '1px solid #dee2e6' }}>
                    {code.churnAPI === 'HTTP' ? churnHttp : churnCurl}
                </pre>
                <Button onClick={copyToClipboard} id="code2" title="Copy to Clipboard" variant="primary" style={{ marginTop: '10px' }}>
                    Copy
                </Button>
            </Col>
        </Row>
    )
}