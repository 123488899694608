//by shreya saraswat
import React, { useState } from "react";
import { Col, Row, Form, Button, ButtonToolbar, ButtonGroup, Dropdown, OverlayTrigger, Tooltip, Card } from 'react-bootstrap';
import whatsappPic from "../../assets/img/mobileScreens/Whatsapp.png"
import { createWhatsappTemplateMutation } from "api/Enterprise/Template/createWhatsappTemplate";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/solid";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import { MdTextFields } from "react-icons/md";
import { ImImages } from "react-icons/im";
import { MdOutlineVideocam } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import { DropFilesForm, DropImagesForm, DropVideosForm } from "components/Forms";
import { XCircleIcon } from "@heroicons/react/outline";

export const WhatsappTemplateComponent = () => {
    const [header, setHeader] = useState('none');
    const handleHeader = (e) => {
        setHeader(e.target.value);
    }
    //variables
    const [inputFields, setInputFields] = useState([]);
    const AddVars = () => {
        setInputFields([...inputFields, ""]);
    };
    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        values[index] = event.target.value;
        setInputFields(values);
        console.log('valuesssssss', inputFields);
    };
    //Buttons
    const [btnType, setBtnType] = useState('');
    const ChooseBtnType = (type) => {
        setBtnType(type);
        console.log('type', btnType);
    };
   
    const deleteButton = (id) => {
        console.log('id', id);
    }


    //postdata
    const [dataWhatsapp, setDataWhatsapp] = useState({});
    const handleform2 = (e) => {
        if (e.target.name === 'file') {
            setDataWhatsapp({
                ...dataWhatsapp,
                file: e.target.files[0],
            });
        } else if (e.target.name === 'image') {
            setDataWhatsapp({
                ...dataWhatsapp,
                image: e.target.image[0],
            });
        } else if (e.target.name === 'video') {
            setDataWhatsapp({
                ...dataWhatsapp,
                video: e.target.video[0],
            });
        } else {
            setDataWhatsapp({
                ...dataWhatsapp,
                [e.target.name]: e.target.value,
            });
        }
        console.log('datataa', dataWhatsapp);
    };
    // const [createWhatsapp] = createWhatsappTemplateMutation();
    // const postdata = {
    //     name: dataWhatsapp.name,
    //     lang_code: "en",//en
    //     category: "MARKETING",//'UTILITY', 'MARKETING', 'AUTHENTICATION'
    //     header_format: "TEXT",//'TEXT', 'IMAGE', 'VIDEO', 'DOCUMENT'
    //     header: "this is tetsing 1 header",
    //     template_content: "lets test first template for {{1}}",
    //     template_variables: ["utkarsh"],
    //     footer: "Thanks from utkarsh",
    //     buttons: [
    //         {
    //             type: "PHONE_NUMBER",
    //             text: "Yes",
    //             phone_number: "+918317645226"
    //         },
    //         {
    //             type: "URL",
    //             text: "website",
    //             url: "http://www.google.com/",
    //             example: [
    //                 "cust_info"
    //             ]
    //         }
    //     ]
    // }
    const submitWhatsapp = (e) => {
        //     e.preventDefault();
        //     createWhatsapp(postdata)
        //         .then((success) => {
        //             if (success?.data?.statusCode === 200) {
        //                 console.log('success');
        //             } else if (success?.data?.statusCode > 300) {
        //                 console.error(' failed ');
        //             }
        //         })
        //         .catch((error) => {
        //             console.error(' failed', error);
        //         });
    }

    return (
        <Row>
            <Col xs={8} lg={8}>
                <Form className="card p-3">
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Template</Form.Label>
                            <Form.Control type="text" required placeholder="abc" maxLength={60} name="header" onChange={handleform2} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Category</Form.Label>
                            <Form.Select>
                                <option defaultValue>Marketing</option>
                                <option>Utility</option>
                                <option>Authentication</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Language</Form.Label>
                            <Form.Select disabled>
                                <option defaultValue>English</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Template Type</Form.Label>
                            <Form.Select>
                                <option defaultValue>Standard</option>
                                <option>Dynamic</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                </Form>

                <Form className="card p-3 mt-3">
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Header (Optional)</Form.Label>
                            <div className="d-flex flex-wrap">
                                <Button onClick={handleHeader} variant={header === 'none' ? "secondary" : "gray-300"} value={"none"} className="m-2"><MdOutlineDoNotDisturbAlt /> None</Button>
                                <Button onClick={handleHeader} variant={header === 'text' ? "secondary" : "gray-300"} value={"text"} className="m-2"><MdTextFields /> Text</Button>
                                <Button onClick={handleHeader} variant={header === 'image' ? "secondary" : "gray-300"} value={"image"} className="m-2"><ImImages />  Image</Button>
                                <Button onClick={handleHeader} variant={header === 'video' ? "secondary" : "gray-300"} value={"video"} className="m-2"><MdOutlineVideocam /> Video</Button>
                                <Button onClick={handleHeader} variant={header === 'doc' ? "secondary" : "gray-300"} value={"doc"} className="m-2"><IoDocumentTextOutline /> Document</Button>
                            </div>
                        </Form.Group>
                    </Row>
                    {header === 'text' && <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Header</Form.Label>
                            <Form.Control type="text" required placeholder="provide header" maxLength={60} name="header" onChange={handleform2} />
                        </Form.Group>
                    </Row>}
                    {header === 'image' && <Row className="mb-3">
                        <Form.Group as={Col}>
                            <DropImagesForm onFilesUploaded={handleform2} />
                        </Form.Group>
                    </Row>}
                    {header === 'video' && <Row className="mb-3">
                        <Form.Group as={Col}>
                            <DropVideosForm onFilesUploaded={handleform2} />
                        </Form.Group>
                    </Row>}
                    {header === 'doc' && <Row className="mb-3">
                        <Form.Group as={Col}>
                            <DropFilesForm onFilesUploaded={handleform2} />
                        </Form.Group>
                    </Row>}
                </Form>

                <Form className="card p-3 mt-3">
                    <Form.Group className="mb-3">
                        <div className="d-flex justify-content-between">
                            <Form.Label>Content Body</Form.Label>
                            <Button onClick={AddVars} bsPrefix="text" style={{ border: "none", background: "none", padding: 0, color: "inherit" }} variant="primary" className="d-flex align-items-center mb-1">
                                <PlusIcon className="icon icon-xs me-2" />Variable
                            </Button>
                        </div>
                        <Form.Control as="textarea" rows="7" required placeholder="Enter Message Content" name="template_content" maxLength={1032} onChange={handleform2} />
                    </Form.Group>

                    <Row className="mb-3">
                        {inputFields.map((input, index) => (
                            <Form.Group as={Col} key={index}>
                                <Form.Label>Var {index + 1}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Value"
                                    value={input}
                                    onChange={(event) => handleInputChange(index, event)}  // Pass index to handleVariables
                                    name="template_variables"
                                />
                            </Form.Group>
                        ))}

                    </Row>

                    <Form.Group className="mb-3">
                        <Form.Label>Footer</Form.Label>
                        <Form.Control type="text" required placeholder="abc" maxLength={60} name="footer" onChange={handleform2} />
                    </Form.Group>
                </Form>

                <Form className="card light p-3 mt-3">
                    <Row className="pb-3">
                        <Form.Group as={Col}>
                            <Form.Label htmlFor="basic-url">Button (Optional)</Form.Label>
                        </Form.Group>
                        <Dropdown as={Col} className="d-flex">
                            <Dropdown.Toggle as={Button} variant="white" className="d-inline-flex alignpitems-center arrow-none ms-auto">
                                Add Buttons<ChevronDownIcon className="icon icon-xs ms-1" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
                                <Dropdown.Item className="d-flex align-items-center hover-info" onClick={() => ChooseBtnType('text')}>
                                    Quick Reply
                                </Dropdown.Item>
                                <Dropdown.Divider as="div" className="my-1" />
                                <Dropdown.Item as="div" className="d-flex align-items-start flex-column nestedDropdown">
                                    Call-to-action
                                    <ul className="list-unstyled ms-2 mt-2 mb-0">
                                        <li onClick={() => ChooseBtnType('url')}>
                                            <p className="dropdown-link">Visit Website</p>
                                        </li>
                                        <li onClick={() => ChooseBtnType('phone')}>
                                            <p className="dropdown-link">Call Phone Number</p>
                                        </li>
                                        <li onClick={() => ChooseBtnType('none')}>
                                            <p className="dropdown-link">Complete Form</p>
                                        </li>
                                    </ul>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Row>
                    <div className="card p-2">
                        <Row>
                            <Form.Label className="border-bottom pb-2 text-info">Quick Reply</Form.Label>
                            <Form.Group as={Col} lg={9} className="d-flex align-items-center my-2">
                                <Form.Control type="text" required placeholder="Button text" maxLength={10} name="text-button-text" onChange={handleform2} />
                                <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                                    <Card.Link className="ms-2" onClick={() => deleteButton()}>
                                        <XCircleIcon className="icon icon-xs text-danger" />
                                    </Card.Link>
                                </OverlayTrigger>
                            </Form.Group>
                        </Row>
                    </div>

                    <div className="card p-2 mt-2">
                        <Form.Label className="border-bottom pb-2 text-info">Call-to-action</Form.Label>
                        <div className="mb-3">
                            <Form.Label>URL</Form.Label>
                            <Row id="url1">
                                <Col md={11}>
                                    <Row>
                                        <Col md={2} className="pe-0">
                                            <Form.Select disabled>
                                                <option>Static</option>
                                            </Form.Select>
                                        </Col>
                                        <Col md={6} className="pe-0">
                                            <Form.Control
                                                type="url"
                                                placeholder="https://example.com"
                                                name="url-button-text"
                                                onChange={handleform2}
                                            />
                                        </Col>
                                        <Col md={4} className="pe-0">
                                            <Form.Control
                                                type="text"
                                                placeholder="Button text"
                                                name="url-button-url"
                                                onChange={handleform2}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={1} className="d-flex align-items-center">
                                    <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                                        <Card.Link onClick={()=>deleteButton('url1')}>
                                            <XCircleIcon className="icon icon-xs text-danger" />
                                        </Card.Link>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Form.Label>Phone Number</Form.Label>
                            <Row>
                                <Col md={11}>
                                    <Row>
                                        <Col md={5} className="pe-0">
                                            <Form.Control
                                                type="text"
                                                placeholder="Button text"
                                                name="url-button-url"
                                                onChange={handleform2}
                                            />
                                        </Col>
                                        <Col md={7} className="pe-0">
                                            <Form.Control
                                                type="url"
                                                placeholder="https://example.com"
                                                name="url-button-text"
                                                onChange={handleform2}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={1} className="d-flex align-items-center">
                                    <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                                        <Card.Link onClick={() => deleteButton()}>
                                            <XCircleIcon className="icon icon-xs text-danger" />
                                        </Card.Link>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Form>
                <div className="d-flex mt-3">
                    <Button variant="gray-800" type="submit" className="animate-up-2">
                        Send Approval
                    </Button>
                    <Button variant="gray-800" type="submit" className="ms-2 animate-up-2">
                        Submit
                    </Button>
                </div>
            </Col>
            <Col xs={4} lg={4}>
                <div className="img-cont">
                    <img src={whatsappPic} alt="whatsapp preview" />
                </div>
            </Col>
        </Row>
    )
}