//by shreya saraswat
import React, { Fragment, useState, useEffect } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CalendarIcon, CheckIcon, CloudDownloadIcon, CogIcon, HomeIcon, PlusIcon, SearchIcon, XCircleIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, InputGroup, Dropdown, Nav, ToastContainer, Toast, Card, Table, OverlayTrigger, Tooltip, Pagination, Breadcrumb } from 'react-bootstrap';
import { useGetCampaignListMutation } from "Services/services";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Routes } from "routes";
import Datetime from "react-datetime";



const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary me-3',
        cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));


export default () => {

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success');

    const [tableData, setTableData] = useState([]);

    // const [bulkOption, setBulkOption] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [status, setStatus] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const startDate = start ? moment(start).format("YYYY-MM-DD") : "";
    const endDate = end ? moment(end).format("YYYY-MM-DD") : "";

    const [pagelimit, setPagelimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState('');

    const [CampaignList] = useGetCampaignListMutation();

    useEffect(() => {
        const fetchTableData = async () => {
            CampaignList({ page: currentPage, limit: pagelimit, status: status, search: searchValue, startDate: startDate, endDate: endDate })
                .then((response) => {
                    if (response?.data?.statusCode === 200) {
                        setTableData(response?.data?.data?.result?.data);
                        setTotalPages(response?.data?.data?.result?.totalPages)
                    } else {
                        setTableData({});
                    }
                })
                .catch((error) => {
                    alert(error);
                    setTableData({});
                });
        };
        fetchTableData();
    }, [searchValue, status, currentPage, pagelimit, startDate, endDate])

    const changeSearchValue = (e) => {
        setSearchValue(e.target.value);
    };
    const handleStatus = (e) => {
        setStatus(e.target.value);
    };

    const deleteUsers = async (ids) => {
        console.log('ids', ids);
        const usersToBeDeleted = ids;
        // const usersNr = usersToBeDeleted.length;
        const textMessage = "Are you sure do you want to delete this template?";

        const result = await SwalWithBootstrapButtons.fire({
            icon: "error",
            title: "Confirm deletion",
            text: textMessage,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel"
        });

        if (result.isConfirmed) {
            const newUsers = tableData.filter(f => !usersToBeDeleted.includes(f.id));
            const confirmMessage = "This template has been deleted.";

            setTableData(newUsers);
            await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');
        }
    }

    const downloadList = (ids) => {
        console.log('dnld id', ids);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const TableRow = (props) => {
        const statusVariant = props.status === "approved" ? "success"
            : props.status === "pending" ? "purple"
                : props.status === "rejected" ? "danger" : "primary";
        return (
            <tr className="border-bottom">
                <td><span className="fw-bold text-info">{props.campaign_name}</span></td>
                <td><span className="fw-normal">{moment(props.campaign_time).format('YYYY/MM/DD')}</span></td>
                <td><span className={`fw-normal text-${statusVariant}`}>{props.status}</span></td>
                <td><span className="fw-normal">{props.template}</span></td>
                <td><span className="fw-normal">{props.template_id}</span></td>
                <td><span className="fw-normal">{props.header}</span></td>
                <td><span className="fw-normal">{props.header_id}</span></td>
                <td><span className="fw-normal">{props.contact_mode}</span></td>
                <td><span className="fw-normal">{props.b_party_details}</span></td>
                <td><span className="fw-normal">{props.pe_id}</span></td>
                <td><span className="fw-normal">{props.created_by}</span></td>
                <td>
                    <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Download</Tooltip>}>
                        <Card.Link onClick={() => downloadList(props.id)}>
                            <CloudDownloadIcon className="icon icon-xs text-success" />
                        </Card.Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                        <Card.Link className="ms-2" onClick={() => deleteUsers(props.id)}>
                            <XCircleIcon className="icon icon-xs text-danger" />
                        </Card.Link>
                    </OverlayTrigger>
                </td>
            </tr>
        );
    };

    return (
        <Fragment>

            <ToastContainer position="top-end" className="p-3">
                <Toast
                    bg={toastVariant}
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    delay={3000}
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">Notification</strong>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>

            <div className="d-flex align-items-start my-3">
                <div className="d-block">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item linkAs={Link}><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Campaign List</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Campaign List</h4>
                    {/* <p className="mb-0">Your SMS/WhatsApp/RCS template analytics dashboard.</p> */}
                </div>
                <Button as={Link} to={Routes.CampaignCreate.path} variant="secondary" className="d-inline-flex align-items-center ms-auto">
                    <PlusIcon className="icon icon-xs me-2" />
                    Campaign
                </Button>
            </div>

            <div className="table-settings mb-2">
                <Row className="justify-content-between align-items-center">
                    <Col md={12} lg={11} className="d-md-flex">
                        <InputGroup className="me-2 me-lg-3 fmxw-300 mb-2 mb-md-0">
                            <InputGroup.Text>
                                <SearchIcon className="icon icon-xs" />
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Campaign Name/Template ID/Header"
                                value={searchValue}
                                onChange={changeSearchValue}
                            />
                        </InputGroup>
                        <Form.Select onChange={handleStatus} value={status} className="me-2 me-lg-3 fmxw-300 mb-2 mb-md-0">
                            <option value="" defaultValue>All Status</option>
                            <option value="approved">Approved</option>
                            <option value="pending">Pending</option>
                            <option value="rejected">Rejected</option>
                        </Form.Select>
                        <Form.Group id="DateRange">
                            <InputGroup className="d-flex flex-nowrap">
                                <InputGroup.Text>
                                    <CalendarIcon className="icon icon-xs" />
                                </InputGroup.Text>
                                <Datetime
                                    timeFormat={false}
                                    onChange={setStart}
                                    renderInput={(props, openCalendar) => (
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Start Date"
                                            value={startDate}
                                            onFocus={openCalendar}
                                            style={{ width: "150px", borderRadius: "0" }}
                                            onChange={() => { }} />
                                    )} />
                                <Datetime
                                    timeFormat={false}
                                    onChange={setEnd}
                                    isValidDate={currDate => currDate.isAfter(start)}
                                    initialViewDate={end}
                                    renderInput={(props, openCalendar) => (
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="End Date"
                                            value={endDate}
                                            onFocus={openCalendar}
                                            style={{ width: "150px", borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                                            onChange={() => { }} />
                                    )} />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={12} lg={1} className="d-flex justify-content-end">
                        <ButtonGroup className="ms-auto">
                            <Dropdown>
                                <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                                    <CogIcon className="icon icon-sm" />
                                    <span className="visually-hidden">Toggle Dropdown</span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                                    <span className="small ps-3 fw-bold text-dark">Show</span>
                                    {[10, 20, 30].map((limit) => (
                                        <Dropdown.Item
                                            key={limit}
                                            className={`d-flex align-items-center fw-bold ${limit === 30 ? 'rounded-bottom' : ''}`}
                                            onClick={() => setPagelimit(limit)}
                                        >
                                            {limit} {pagelimit === limit && <CheckIcon className="icon icon-xxs ms-auto" />}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </ButtonGroup>
                    </Col>
                </Row>
            </div>

            <Card border="0" className="table-wrapper table-responsive shadow">

                <Card.Body>
                    <Table responsive className="align-items-center table-flush">
                        <thead className="thead-light">
                            <tr>
                                <th className="border-bottom">Campaign Name</th>
                                <th className="border-bottom">Campaign Time</th>
                                <th className="border-bottom">Status</th>
                                <th className="border-bottom">Template</th>
                                <th className="border-bottom">Template ID</th>
                                <th className="border-bottom">Header</th>
                                <th className="border-bottom">Header ID</th>
                                <th className="border-bottom">Contact Mode</th>
                                <th className="border-bottom">B-party Details</th>
                                <th className="border-bottom">PE ID</th>
                                <th className="border-bottom">Created By</th>
                                <th className="border-bottom">Action</th>
                            </tr>
                        </thead>
                        <tbody className="border-0">
                            {tableData.length > 0 ? tableData.map((u, index) => (
                                <TableRow key={index + 1} index={index + 1} {...u} />
                            )) :
                                <tr>
                                    <td colSpan="13" className="text-center text-danger">No Data Found</td>
                                </tr>}
                        </tbody>
                    </Table>
                    <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                        <Nav>
                            <Pagination className="mb-0">
                                <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
                                    Previous
                                </Pagination.Prev>
                                {[...Array(totalPages).keys()].map(number => (
                                    <Pagination.Item
                                        key={number + 1}
                                        active={currentPage === number + 1}
                                        onClick={() => handlePageChange(number + 1)}
                                    >
                                        {number + 1}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
                                    Next
                                </Pagination.Next>
                            </Pagination>
                        </Nav>
                        <small className="fw-normal small mt-4 mt-lg-0">
                            Showing <b>{tableData.length}</b> out of <b>{pagelimit}</b> entries
                        </small>
                    </Card.Footer>
                </Card.Body>
            </Card>

        </Fragment>
    );
};
